.container {
    position: relative;
    height: 250px;
    border-radius: 10px;
}

.shape {
    position: absolute;
    width: 100%;
    height: 100%;
}

.container:hover {
    cursor: pointer;
}

.textbox {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: rgb(80, 78, 78);
}

.technology {
    font-weight: 900;
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.customer {
    font-weight: 100;
}