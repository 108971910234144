/* source-sans-pro-200 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/source-sans-pro-v21-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v21-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-200.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v21-latin-200.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/source-sans-pro-v21-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/source-sans-pro-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v21-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/source-sans-pro-v21-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v21-latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* merriweather-sans-300 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/merriweather-sans-v22-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/merriweather-sans-v22-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/merriweather-sans-v22-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/merriweather-sans-v22-latin-300.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-regular - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/merriweather-sans-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/merriweather-sans-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/merriweather-sans-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/merriweather-sans-v22-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-500 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/merriweather-sans-v22-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/merriweather-sans-v22-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/merriweather-sans-v22-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/merriweather-sans-v22-latin-500.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-600 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/merriweather-sans-v22-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/merriweather-sans-v22-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/merriweather-sans-v22-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/merriweather-sans-v22-latin-600.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-700 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/merriweather-sans-v22-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/merriweather-sans-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/merriweather-sans-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/merriweather-sans-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/merriweather-sans-v22-latin-700.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}


body {
    
    box-sizing: border-box;
    
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.app{
    padding:20px;
    
}

.center{
    display:flex;
    justify-content: center;
    align-items: center;
}


.lds-heart {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
  }
  .lds-heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #aaaaaa;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .lds-heart div:after,
  .lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #aaaaaa;
  }
  .lds-heart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  .lds-heart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
  @keyframes lds-heart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }
  
  
.impressum{
  margin-top: 10px;

}

.impressum a{
  text-decoration: none;
  color:grey;
}
