.container {
    height: 100%;
    color: rgb(77, 77, 77);
    line-height: 1.3rem;
    padding-top: 30px;
    text-align: left;
}

.container h2 {
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 20px;
}

.card {
    display: block;
}

@media only screen and (min-width: 600px) {
    .card {
        display: flex;
    }
}

.card img {
    width: 100%;
}

.infotext {
    position: relative;
    border-style: solid;
    border-color: gray;
    padding: 20px;
}

.cardtext {
    flex:1;
    padding: 15px;
}
.cardImage{
    flex:1;
}

.cardlist {
    font-weight: 100;
}

.fat {
    font-weight: 900;
    margin-right: 2px;
    margin-bottom: 15px;
}

.thim {
    font-weight: 100;
}

.color1 {
    color: yellow;
}

.color2 {
    color: orange;
}

.size1 {
    font-size: 1.4rem;
}

.size2 {
    font-size: 2rem;
}

.infotext span {
    display: block;
}

.heartcontainer {
    position: fixed;
    z-index: -1000;
    top: 0;
    right: 0;
    transform: translate(50px, 15px);
}

.ldsheart {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    transform: rotate(45deg);
}

.ldsheart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #aaaaaa;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ldsheart div:after, .ldsheart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #aaaaaa;
}

.ldsheart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
}

.ldsheart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}