.container {
    height: 100vh;
    padding: 50px; 
    overflow: scroll;
}

.container h1 {
    font-weight: 800;
    font-size: 2rem;
}

.container a{
    text-decoration: none;
    color:gray;
}

.container h2 {
    font-weight: 100;
    font-size: 1.5rem;
    margin-top: 1rem;
}

.container h6{
    margin-top: 1rem;
}

.container p {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 100;
    font-size: 1rem;
    text-align: justify;
}

.image{
    -webkit-box-shadow: -1px 15px 69px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 15px 69px -9px rgba(0,0,0,0.75);
    box-shadow: -1px 15px 69px -9px rgba(0,0,0,0.75);
    /*width:100px;*/
    max-width: 800px;
}
.icon{
    font-size:50px;
}

.icon:hover{
    cursor: pointer;
}

.header{
    display:flex;
    justify-content:space-between;
}

@media only screen and (max-width: 600px) {
    .image{
        max-width: 800px;
        width:100%;
    }

    .icon{
        font-size:30px;
    }
}