.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.slogan ul {
    list-style-type: none;
    line-height: 2.1rem;
    margin-bottom: 30px;
}

.slogan {
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 800;
    font-size: 2rem;
}

.sloganContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.small {
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 100;
}

.company {
    font-weight: 100;
    font-size: 1rem;
    letter-spacing: 0.5rem;
    color: rgb(201, 199, 199);
    margin-bottom: 10px;
}

.company:hover{
    cursor: pointer;
}

.contact{
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;
    color:gray;
}

.contact:hover{
    cursor: pointer;
}
.contact div{
    padding:4px;
}

.navigation {
    text-align: right;
    color: rgb(201, 199, 199);
}

.navigation li {
    text-align: right;
    margin-bottom: 5px;
    letter-spacing: 0.3rem;
}

.navigation li:hover {
    cursor: crosshair;
}

@media only screen and (max-width: 600px) {
    .navigation {
       display: none;
    }

    .slogan ul {
        list-style-type: none;
        line-height: 1.9rem;
        margin-bottom: 30px;
    }
    
    .slogan {
        font-family: 'Merriweather Sans', sans-serif;
        font-weight: 800;
        font-size: 1.5rem;
        margin-bottom:20px;
    }
}